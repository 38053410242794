/**
 * Object
 * ICON
 */

.icon {
  display: inline-block;
  width: 18px;
  height: 18px;

  fill: currentColor;
  vertical-align: -4px;

  will-change: transform; // Prevent cropped/blurry icons on Firefox
  pointer-events: none;
}

.icon-75 {
  width: 12px;
  height: 12px;

  vertical-align: -1px;
}

.icon-125 {
  width: 20px;
  height: 20px;

  vertical-align: -5px;
}

.icon-150 {
  width: 24px;
  height: 24px;

  vertical-align: -7px;
}

.icon-faded {
  opacity: 0.66;
}
