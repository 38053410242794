/**
 * Object
 * RATING
 */

// Default styling
.rateit {
  display: inline-block;
  position: relative;

  -webkit-user-select: none;
  -webkit-touch-callout: none;

  .rateit-range {
    position: relative;
    display: inline-block;
    height: 16px;
    outline: none;

    * {
      display: block;
    }
  }

  .rateit-hover,
  .rateit-selected {
    position: absolute;
    left: 0px;
  }
}

// Expertise rating
.star-rating {
  .rateit-range,
  .rateit-hover,
  .rateit-selected {
    background: url('../images/rating/star-rating.png');
  }

  .rateit-selected {
    background-position: left -48px;
  }

  .rateit-hover {
    background-position: 0 -72px;
  }
}

// Appreciation rating
.thumb-rating {
  .rateit-range,
  .rateit-hover,
  .rateit-selected {
    background: url('../images/rating/thumb-rating.png') no-repeat;
  }

  .rateit-hover.v-2 {
    background-position: 0 -24px;
  }
  .rateit-hover.v-1 {
    background-position: 0 -48px;
  }
  .rateit-hover.v0 {
    background-position: 0 -72px;
  }
  .rateit-hover.v1 {
    background-position: 0 -96px;
  }
  .rateit-hover.v2 {
    background-position: 0 -120px;
  }

  .rateit-selected.v-2 {
    background-position: 0 -24px;
  }
  .rateit-selected.v-1 {
    background-position: 0 -48px;
  }
  .rateit-selected.v0 {
    background-position: 0 -72px;
  }
  .rateit-selected.v1 {
    background-position: 0 -96px;
  }
  .rateit-selected.v2 {
    background-position: 0 -120px;
  }
}

// Expertise legend
.star-icon {
  padding-left: 24px;

  background: url('../images/rating/star-rating-legend.png') no-repeat;

  &.val1 {
    background-position: 0 0;
  }
  &.val2 {
    background-position: 0 -18px;
  }
  &.val3 {
    background-position: 0 -36px;
  }
  &.val4 {
    background-position: 0 -54px;
  }
  &.val5 {
    background-position: 0 -72px;
  }
}

// Appreciation legend
.thumb-icon {
  padding-left: 24px;

  background: url('../images/rating/thumb-rating-legend.png') no-repeat;

  &.val2 {
    background-position: 0 0;
  }
  &.val1 {
    background-position: 0 -18px;
  }
  &.val0 {
    background-position: 0 -36px;
  }
  &.val-1 {
    background-position: 0 -54px;
  }
  &.val-2 {
    background-position: 0 -72px;
  }
}

// Read only visualization for ratings
.read-only-rating {
  height: 16px;
  width: 80px;
  margin-left: auto;
  margin-right: 0px;
  overflow: hidden;

  text-indent: 9999px;

  background: url('../images/rating/readonly-visualization.png') no-repeat;

  &.stars.v1 {
    background-position: 0 -16px;
  }
  &.stars.v2 {
    background-position: 0 -32px;
  }
  &.stars.v3 {
    background-position: 0 -48px;
  }
  &.stars.v4 {
    background-position: 0 -64px;
  }
  &.stars.v5 {
    background-position: 0 -80px;
  }

  &.thumbs.v {
    background-position: 0 -96px;
  }
  &.thumbs.v-2 {
    background-position: 0 -112px;
  }
  &.thumbs.v-1 {
    background-position: 0 -128px;
  }
  &.thumbs.v0 {
    background-position: 0 -144px;
  }
  &.thumbs.v1 {
    background-position: 0 -160px;
  }
  &.thumbs.v2 {
    background-position: 0 -176px;
  }
}
