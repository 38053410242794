/*----------------------------------------*\
  AGENDA
\*----------------------------------------*/

.agenda {
  tbody {
    tr + tr {
      td,
      th {
        border-top-width: 2px;
      }
    }
  }
}

.agenda-weekday {
  vertical-align: top !important;

  &.off {
    background: repeating-linear-gradient(
      45deg,
      rgba(238, 238, 238, 0.3),
      rgba(238, 238, 238, 0.3) 5px,
      rgba(255, 255, 255, 0.3) 5px,
      rgba(255, 255, 255, 0.3) 10px
    );
  }

  &:hover .agenda-weekday-new-timesheet {
    display: block;
  }
}

.agenda-weekday-new-timesheet {
  display: none;
}

.agenda-weekday-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;

  margin: -4px -6px 10px -6px;
  padding: 0 4px 3px 0;

  border-bottom: 1px solid $warm-grey-100;
}

.agenda-weekday-body {
  height: 85px;

  display: flex;
  flex-direction: column;
  gap: $half-spacing-unit;
}

.agenda-weekday-timesheets {
  margin-top: auto;
}

.agenda-weekday-number {
  display: block;
  padding: 2px 5px;

  font-size: 18px;
  color: $warm-grey-600;

  &.today {
    color: white;

    background-color: $brand-alt;
    border-radius: 50%;
  }
}

.agenda-total {
  background-color: $warm-grey-50;

  vertical-align: bottom !important;
  border-left-width: 2px !important;
}
