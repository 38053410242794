/**
 * Objects
 * DRAG
 */

.drag-ghost {
  opacity: 0.5;
  background: $brand-secondary !important;
}

.drag-handle {
  cursor: grab;
}
