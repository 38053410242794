/*----------------------------------------*\
  LIST STACKED
\*----------------------------------------*/

.list-stacked {
  @extend .list-unstyled;

  > li + li {
    margin-top: 10px;
  }
}
