/**
 * Object
 * LIST FLEX
 */

.list-flex {
  margin-left: -#{$half-spacing-unit};
  margin-right: -#{$half-spacing-unit};

  display: flex;
  flex-wrap: wrap;
}

.list-flex-item {
  width: 100%;
  padding: 0 $half-spacing-unit;
  @media (min-width: $screen-xs-min) {
    width: 50%;
  }
  @media (min-width: $screen-sm-min) {
    width: 33%;
  }
  @media (min-width: $screen-md-min) {
    width: 25%;
  }
  @media (min-width: $screen-lg-min) {
    width: 20%;
  }
}
