/**
 * Helpers
 * TEXT
 */

.text-huge {
  font-size: $font-size-huge;
}

.text-large {
  font-size: $font-size-large;
}

.text-small {
  font-size: $font-size-small;
}

.text-default {
  color: $text-color;
}

.text-clickable {
  cursor: pointer;
}

.text-warm-gray-50 {
  color: $warm-grey-50;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.text-bold {
  font-weight: bold;
}

.rich-text {
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }

  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 15px;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 13px;
  }

  img {
    max-width: 100%;
    height: auto;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}

[data-issue-key] {
  position: relative;
  border-bottom: 2px dotted $brand-main;
  cursor: help;
}

.issue-tooltip {
  position: absolute;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
  width: 300px;
  z-index: 9999999;
}
