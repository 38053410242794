/*----------------------------------------*\
  FONTS
\*----------------------------------------*/

@font-face {
  font-family: 'Archivo';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Archivo-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Archivo';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Archivo-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Archivo';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('../fonts/Archivo-Italic.woff2') format('woff2');
}
