/**
 * Object
 * GANTT BAR
 */

.gantt-bar {
  background-color: #888;
  height: 14px;
  position: relative;

  &:hover .tooltip {
    display: block;
  }

  &.state-1 {
    background-color: #2f7ed8;
  }
}

.gantt-bar-tooltip {
  position: absolute;
  display: none;
  width: 160px;
  border: 1px solid grey;
  padding: 3px;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1;
  left: -150px;
  top: 8px;
  text-align: center;
}
