/*----------------------------------------*\
  CHANGELOG
\*----------------------------------------*/

.changelog-line:target {
  .changlog-line-text {
    display: block;
    padding: 4px;
    margin: -4px;

    background-color: $highlight-color;
  }
}

.changelog-title {
  margin-top: 1.5em;
  font-weight: bold;
}
