/**
 * FORMS
 */

.label-required::after {
  content: ' *';
  font-size: 1.5em;
  line-height: 0;
  color: $brand-danger;
  vertical-align: middle;
}

// Input
input.text-muted {
  color: #898a81;
}

.input-group-addon.text-muted {
  color: #898a81;
}
