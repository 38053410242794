.presences {
  .non-core {
    opacity: 0.5;
  }
  .week-end {
    background-color: #eee;
    width: 20px;
  }
  .today {
    font-weight: bold;
    border-left: 2px solid #5d8d3a !important;
    border-right: 2px solid #5d8d3a;
    text-shadow: 0 0 0 #5d8d3a;
  }
  td,
  th {
    max-width: 60px;
    text-align: center;
    font-size: 0.7rem;
  }
  td {
    height: 45px;
    overflow: hidden;
    font-size: 0.7rem;
    width:30px;
  }
  tr{
    border-top: 2px dotted transparent !important;
    &:hover {
      border-top: 2px dotted black !important;
      border-bottom: 2px dotted black !important;
    }
  }
  .busy {
  }
  .holidays {
    background-color: rgba(156, 49, 71, 0.2);
  }
  .noproject {
    background-color:rgba(156, 49, 71, 0.2);
  }
  .off {
    background-color: rgba(156, 49, 71, 0.2);
  }
  li {
    &:before {
      display: inline !important;
      margin-right: 5px;
    }
  }
}
