.toastui-editor-toolbar,
.toastui-editor-defaultUI-toolbar,
.toastui-editor-dropdown-toolbar {
  height: 38px !important;
}

html:not(.theme-dark) .toastui-editor-defaultUI-toolbar,
html:not(.theme-dark) .toastui-editor-dropdown-toolbar {
  background-color: $warm-grey-50 !important;
}

.toastui-editor-defaultUI-toolbar button {
  margin: 3px 5px !important;
}
.toastui-editor-contents {
  min-height: inherit;
}
