/**
 * Object
 * RISK-LEVEL
 */

.risk-level {
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 0.3em;
  border-width: 0.1em;
  border-color: lightgrey;
  border-style: solid;
  background-color: $gray-lighter;
}

.risk-level--risky {
  background-color: $brand-danger;
}

.risk-level--moderate {
  background-color: $brand-warning;
}

.risk-level--ok {
  background-color: $brand-success;
}
