/**
 * Object
 * PUSHER
 */

.pusher {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pusher__body {
  max-width: 100%;
  flex: 1 0 auto;
}
