/**
 * Page
 * ERROR
 */

.error-page {
}

.error-page-title {
  font-size: 3.125em;
}

.error-page-mascot {
  width: 360px;
  max-width: 100%;

  @media (min-width: $screen-md-min) {
    width: 440px;
  }
}
