.page-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: $half-spacing-unit;
  column-gap: $base-spacing-unit;

  // Some values override Bootstrap default
  // and should be removed with the next update
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.page-header__start,
.page-header__end {
  // Explicitely lower selector specificity so the margin gap below applies
  :where(h1) {
    margin-top: 0;
    margin-bottom: 0;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 0.5em;
  }
}

.page-header__start {
  max-width: 55rem;
}

.page-header__end {
  align-self: flex-end;

  @media (max-width: $screen-xs-min) {
    flex-grow: 1;
  }
}

.page-header__tabs {
  margin-bottom: -$base-spacing-unit - 2;
  margin-right: calc(var(--container-gap) * -1);
  margin-left: calc(var(--container-gap) * -1);

  padding-left: var(--container-gap);
  padding-right: var(--container-gap);
}
