.sql-query{
  padding:10px;
  background-color: #eeeeee;
  border-radius: 5px;
  .sql-comment{
    color: #6e4302;
    font-style: italic;
  }
  .sql-reserved{
    color:purple;
    font-weight: bold;

  }
  .sql-keyword{
    color:blue;
    font-weight: bold;
  }
}
