/**
 * Settings
 * VARIABLES
 */

// Colors
$brand-main: #5d8d3a;
$brand-alt: #68528f;
$highlight-color: #eaffd0;
$caution: #cc8400;

// Spacings
$base-spacing-unit: 24px;
$quarter-spacing-unit: $base-spacing-unit / 4;
$half-spacing-unit: $base-spacing-unit / 2;
$double-spacing-unit: $base-spacing-unit * 2;
$quadruple-spacing-unit: $base-spacing-unit * 4;
