/*!
   ____..--'    .-''-.   _______   .-------.       ____
  |        |  .'_ _   \ \  ____  \ |  _ _   \    .'  __ `.
  |   .-'  ' / ( ` )   '| |    \ | | ( ' )  |   /   '  \  \ 
  |.-'.'   /. (_ o _)  || |____/ / |(_ o _) /   |___|  /  |
     /   _/ |  (_,_)___||   _ _ '. | (_,_).' __    _.-`   |
   .'._( )_ '  \   .---.|  ( ' )  \|  |\ \  |  |.'   _    |
 .'  (_'o._) \  `-'    /| (_{;}_) ||  | \ `'   /|  _( )_  |
 |    (_,_)|  \       / |  (_,_)  /|  |  \    / \ (_ o _) /
 |_________|   `'-..-'  /_______.' ''-'   `'-'   '.(_,_).'

 ZEBRA RESPONSIVE STYLESHEET
 Based on Bootstrap 3.4
 By Liip for Liip.

 © 2017, All rights reserved.
 */

/**
 * SETTINGS
 */

// Settings files are injectect with style-resources-loader to be available in Vue components as well
// See webpack.config.js

@import 'settings/variables';
@import 'settings/bootstrap';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

/**
 * BOOTSTRAP
 */

// Core mixins
@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Reset and dependencies
@import '~bootstrap-sass/assets/stylesheets/bootstrap/normalize';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/print';

// Core CSS
@import '~bootstrap-sass/assets/stylesheets/bootstrap/scaffolding';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/type';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/code';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/grid';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/tables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/forms';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/buttons';

// Components
@import '~bootstrap-sass/assets/stylesheets/bootstrap/component-animations';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/dropdowns';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/button-groups';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/input-groups';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/navs';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/navbar';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/pagination';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/pager';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/labels';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/badges';
// @import '~bootstrap-sass/assets/stylesheets/bootstrap/jumbotron';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/thumbnails';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/alerts';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/progress-bars';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/media';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/list-group';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/panels';
// @import '~bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/wells';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/close';

// Components w/ JavaScript
@import '~bootstrap-sass/assets/stylesheets/bootstrap/modals';
// @import '~bootstrap-sass/assets/stylesheets/bootstrap/tooltip';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/popovers';
// @import '~bootstrap-sass/assets/stylesheets/bootstrap/carousel';

// Utility classes
@import '~bootstrap-sass/assets/stylesheets/bootstrap/utilities';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';

/**
 * BASE
 */

@import 'base/fonts';
@import 'base/type';
@import 'base/layout';
@import 'base/bootstrap-extension';
@import 'base/forms';
@import 'base/print';

/**
 * HELPERS
 */

@import 'helpers/spacings';
@import 'helpers/text';
@import 'helpers/table';
@import 'helpers/visibility';
@import 'helpers/display';

/**
 * OBJECTS
 */

@import 'objects/navbar';
@import 'objects/tabs';
@import 'objects/page-header';
@import 'objects/tablesorter';
@import 'objects/editable';
@import 'objects/favorite';
@import 'objects/user';
@import 'objects/rating';
@import 'objects/list-flex';
@import 'objects/list-stacked';
@import 'objects/list-hybrid';
@import 'objects/notifications';
@import 'objects/loader';
@import 'objects/label';
@import 'objects/markdown';
@import 'objects/pusher';
@import 'objects/highcharts';
@import 'objects/mascot';
@import 'objects/color-box';
@import 'objects/gantt-bar';
@import 'objects/agenda';
@import 'objects/sql';
@import 'objects/card';
@import 'objects/icon';
@import 'objects/drag';
@import 'objects/view-more';
@import 'objects/risk-level';
@import 'objects/satisfaction-survey-container';

/**
 * PAGES
 */

@import 'pages/login';
@import 'pages/timesheets';
@import 'pages/project';
@import 'pages/salaryEvaluation';
@import 'pages/technology';
@import 'pages/changelog';
@import 'pages/search';
@import 'pages/presences';
@import 'pages/error';
@import 'pages/features';
@import 'pages/components';
@import 'pages/sprint';
@import 'pages/satisfactionSurveyEvaluation';
@import 'pages/documentation';

@import 'themes/theme-lama';
@import 'themes/theme-starwars';
@import 'themes/theme-winter';
//@import 'themes/theme-dark';
@import 'themes/theme-halloween';

/**
 * VENDORS
 */

@import 'vendor/select2';
@import 'vendor/select2-bootstrap';
@import 'vendor/x-editable';
@import 'vendor/bootstrap-datepicker3';
@import 'vendor/multiselect';
@import 'vendor/toast-ui-editor';
