@import '~vue-multiselect/dist/vue-multiselect.min.css';

.multiselect {
  min-height: 0;
  width: 100%;
  position: relative;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: inherit;
}

.multiselect__input,
.multiselect__single {
  margin-top: 4px;
  margin-bottom: 0;
}

.multiselect__input {
  margin-top: 5px;
  padding-left: 0;
}

.multiselect__tags {
  min-height: 34px;
  padding: 1px 40px 6px 10px;

  line-height: 1.42857;
  white-space: normal;

  border: 1px solid $input-border;
  border-radius: $input-border-radius;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.multiselect--active {
  .multiselect__tags {
    border-color: $input-border-focus;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  }
}

.multiselect__select {
  height: 34px;
}

.multiselect__placeholder {
  padding-top: 6px;
  margin-bottom: 0;
}

.multiselect__tag {
  padding: 4px 28px 4px 6px;
  margin-top: 6px;
  margin-bottom: 0;

  color: $input-color;
  vertical-align: middle;

  border-radius: 2px;
  border: 1px solid $input-border;
  background-color: #eee;
}

.multiselect__content-wrapper {
  border-color: $input-border-focus;
  background-color: $gray-lighter;
  width: 100%;
  display: block;
  position: absolute;
  z-index: 9999;
}

.multiselect__tag-icon {
  border-radius: 0;
  border-left: 1px solid $input-border;

  &::after {
    color: $warm-grey-800;
  }

  &:focus,
  &:hover {
    background-color: $input-border;

    &::after {
      color: $warm-grey-800;
    }
  }
}
.multiselect__content {
  width: 100%;
}
.multiselect__element {
  width: 100%;
}
.multiselect__option {
  color: $input-color;
  max-width: 100%;
}

.multiselect__option--highlight {
  color: white;
  background-color: $brand-primary;

  &.multiselect__option--selected {
    background-color: $brand-danger;

    &::after {
      background-color: inherit;
    }
  }
}

.multiselect--disabled {
  opacity: 1;
  cursor: not-allowed;
  pointer-events: all;

  .multiselect__tags {
    background-color: $input-bg-disabled;
  }

  .multiselect__select {
    background-color: transparent;
    cursor: not-allowed;
  }
}
