/**
 * Base
 * TYPOGRAPHY
 */

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * Links
 */

a,
summary {
  cursor: pointer;
}

// Highlight current user
a.current-user,
a.current-user:visited {
  color: $brand-alt;
}

a.no-style {
  color: $text-color;
  text-decoration: none;
}

// Highlight external user
a.external-user,
a.external-user:visited {
  color: $caution;
}
a.active {
  font-weight: bold;
}

a[disabled] {
  pointer-events: none;
}

/**
 * Styling
 */

.conjunction {
  position: relative;
  margin: $half-spacing-unit 0;

  text-align: center;
  color: $gray-light;

  &::before {
    content: '';
    width: 100%;
    height: 1px;

    position: absolute;
    top: 50%;
    left: 0;

    border-bottom: 1px solid darken($body-bg, 10%);
  }

  > span {
    display: inline-block;
    padding: 0 $half-spacing-unit;

    position: relative;

    background-color: $body-bg;
  }
}

.break-word {
  word-wrap: break-word;

  table & {
    word-break: break-all;
  }
}

// Even if bootstrap recommend to use a <s> tag, see https://getbootstrap.com/docs/3.3/css/#strikethrough-text
// Sometime it's must easier to add a simple class
.line-through {
  text-decoration: line-through;
}

/**
 * Misc
 */

small.text-muted {
  font-weight: normal;
}

.help-popover-button {
  margin: 0 2px;
}

kbd {
  display: inline-block;
  padding: 3px 7px;

  color: inherit;
  text-transform: capitalize;

  border: 1px solid $warm-grey-200;
  background-color: $warm-grey-50;
  border-radius: 5px;
  box-shadow: 0 3px 0 0 $warm-grey-100;
}
