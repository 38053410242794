/**
 * Object
 * LABEL
 */

.label {
  display: inline-block;
  padding: 0.275em 0.666em;

  font-size: inherit;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);

  border-radius: 30px;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.label-faded {
  opacity: 0.2;
}

.label-alt {
  color: #777;
  text-shadow: none;

  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset;
}

.label-dark {
  background-color: #666;
}
