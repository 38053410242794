/*----------------------------------------*\
  COMPONENTS
\*----------------------------------------*/

.menu {
  position: fixed;
}
.menu-directory {
  display: block;
}

.panel-component .panel-body {
  padding: 10px 10px 0 10px;
}
.panel-component .panel-footer {
  padding: 4px 10px;
}
.panel-component .panel {
  margin-bottom: 15px;
}
