/**
 * Page
 * TIMESHEETS
 */

.timesheets-pager {
  .timesheets-pager-heading {
    @media (max-width: $screen-xs-max) {
      display: block;
      margin-top: 50px;
    }
  }
}

/*
 * Favorites
 */
.favorites-list-item {
  padding-right: 55px;

  &:hover {
    .favorites-list-actions {
      display: block;
    }
  }
}

.favorites-list-actions {
  position: absolute;
  right: 7px;
  top: 0;
  padding: 10px 0;

  display: none;
}

/**
 * Reports
 */
.timesheets-charts {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
}

.timesheets-charts-pie {
  flex: 0 0 auto;
  flex-direction: column;
  padding-left: 12px;
  font-size: 12px;
  width: 100%;

  > * {
    flex: 1 0 auto;
  }

  @media (min-width: $screen-xs-min) {
    width: 50%;
  }
  @media (min-width: $screen-sm-min) {
    width: 33.333%;
  }
  @media (min-width: $screen-md-min) {
    width: 20%;
  }
}

@media (max-width: $screen-xs-max) {
  .tablesorter-sticky-wrapper {
    display: none !important;
  }
}
