/**
 * Custom navbar depending on environment (dev, preprod)
 */

/**
 * Navbar custom mixin
 */

@mixin custom-navbar($parent-class, $color) {
  $navbar-dark: darken($color, 10%);
  $navbar-light: lighten($color, 10%);

  .#{$parent-class} .navbar-inverse {
    background-color: $color;
    background-image: linear-gradient(45deg, $color, lighten($color, 12%));

    .navbar-nav {
      @media (max-width: $grid-float-breakpoint-max) {
        .open .dropdown-menu {
          > li {
            > a:hover,
            > a:focus {
              background-color: $navbar-light;
            }
          }

          .divider {
            background-color: $navbar-dark;
          }
        }
      }
    }

    .navbar-toggle {
      border-color: $navbar-dark;

      &:hover,
      &:focus {
        background-color: $navbar-dark;
      }
    }

    .navbar-collapse,
    .navbar-form,
    .navbar-nav .open .dropdown-menu .divider {
      border-color: $navbar-dark;
    }

    .dropdown-menu > li > form {
      padding: 0 10px;
    }

    input {
      border-color: $navbar-dark;
    }

    .dropdown-menu {
      > .active {
        > a {
          &,
          &:hover,
          &:focus {
            background-color: $navbar-light;
          }
        }
      }
    }
  }
}

@include custom-navbar('theme-pink', #c23e59);
@include custom-navbar('theme-orange', #ec9506);
@include custom-navbar('theme-blue', #05837c);
