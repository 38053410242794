@include custom-navbar('theme-lama', #05837c);

.theme-lama > body {
  background-image: url('/static/images/lama/llama_poya.png');
  .navbar-inverse {
    background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet, red);
    background-repeat: repeat-x;
    .navbar-nav > li > a {
      text-shadow: 1px 1px 2px #000;
    }
  }
}
