/**
 * Base
 * LAYOUT
 */

:root {
  --step-0: clamp(0.88rem, calc(0.88rem + 0vw), 0.88rem);
  --step-1: clamp(1.01rem, calc(0.99rem + 0.08vw), 1.05rem);
  --step-2: clamp(1.16rem, calc(1.12rem + 0.18vw), 1.26rem);
  --step-3: clamp(1.33rem, calc(1.27rem + 0.32vw), 1.51rem);
  --step-4: clamp(1.53rem, calc(1.43rem + 0.49vw), 1.81rem);
  --step-5: clamp(1.76rem, calc(1.61rem + 0.73vw), 2.18rem);

  --container-gap: 12px;

  @media (min-width: $screen-sm-min) {
    --container-gap: #{$base-spacing-unit};
  }
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body.has-navbar {
  padding-top: 60px;
}

.container-fluid {
  max-width: none;
  padding-left: var(--container-gap);
  padding-right: var(--container-gap);

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .navbar-header,
    .navbar-collapse {
      margin-left: calc(var(--container-gap) * -1);
      margin-right: calc(var(--container-gap) * -1);
    }
  }
}

.app {
  display: grid;
  grid-template-columns: minmax(0, auto);
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    'sidenav'
    'before-content'
    'content'
    'footer';

  min-height: 100%;

  @media (min-width: $screen-md-min) {
    grid-template-columns: minmax(0, auto) minmax(0, 1fr);
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'sidenav before-content'
      'sidenav content'
      'footer footer';
  }
}

.app-sidenav {
  grid-area: sidenav;

  padding: $half-spacing-unit;

  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  @media (min-width: $screen-md-min) {
    width: 260px;
    padding: $base-spacing-unit;
  }
}

.site-before-content {
  grid-area: before-content;

  padding-top: $base-spacing-unit;
  padding-bottom: $base-spacing-unit;

  border-bottom: 2px solid $warm-grey-200;
}

.site-content {
  grid-area: content;

  min-height: 100%;
  position: relative;

  padding-top: $base-spacing-unit;
  padding-bottom: $base-spacing-unit;
}

.site-footer {
  grid-area: footer;

  width: 100%;
  padding: $base-spacing-unit 0;

  color: $warm-grey-600;

  background-color: $warm-grey-100;

  .list-inline {
    margin: 0;
  }

  a {
    color: $warm-grey-700;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $warm-grey-1000;
    }
  }
}

.navbar-brand {
  padding-left: 80px;
  margin-right: 10px;

  font-weight: bold;

  position: relative;
}

.navbar-mascot {
  width: 60px;

  position: absolute;
  top: -2px;
  left: 12px;
  z-index: 1;
}

// Ensure anchor targets are not covered by the navbar
*:target {
  scroll-margin-top: 100px;
}
