/**
 * Object
 * NOTIFICATIONS
 */

.notifications-center {
  width: 350px;
  max-width: calc(100% - 30px);
  margin: 0;
  position: fixed;
  right: 15px;
  top: $navbar-height + 15px;
  z-index: 3000; /* to be above the bootstrap modal */
  max-height: 80%;
  overflow-y: auto;
}

.notification {
  margin-bottom: 10px;

  color: white;
  font-size: 16px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

  background-color: rgba(0, 0, 0, 0.8);
  border-radius: $border-radius-base;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);

  + .notification {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  .media-left {
    padding-right: $half-spacing-unit;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.notification-success {
  background-color: transparentize($brand-success, 0.05);
}
.notification-info {
  background-color: transparentize($brand-info, 0.05);
}
.notification-warning {
  background-color: transparentize($brand-warning, 0.05);
}
.notification-error {
  background-color: transparentize($brand-danger, 0.05);
}

.notification-media {
  padding: $half-spacing-unit;
}

.notification-icon {
  font-size: 24px;
}

.zebra-notifications {
  padding: 10px;
  margin: 30px 15px 5px;
  font-size: 1.1em;
  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }
}
