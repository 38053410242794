/**
 * Objects
 * COLOR BOX
 */

.color-box {
  display: inline-block;
  width: 30px;
  height: 16px;
  margin-bottom: -4px;
  margin-right: 3px;

  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

.color-box-warning-level-1 {
  background-color: #fe0;
}
.color-box-warning-level-2 {
  background-color: #fc0;
}
.color-box-warning-level-3 {
  background-color: #fa0;
}
.color-box-warning-level-4 {
  background-color: #f70;
}
.color-box-warning-level-5 {
  background-color: #f40;
}
