/**
 * Object
 * EDITABLE
 */

.editable,
.editable-style {
  position: relative;

  &:not(.editable-ghost):hover,
  &:not(.editable-ghost):focus-visible {
    background-color: #fffbe1 !important;
    cursor: pointer;
    box-shadow: 0 0 0 4px #fffbe1;

    &::after {
      content: '';

      display: block;
      width: 1.125em;
      height: 1.125em;
      margin-left: 4px;

      position: absolute;
      left: 100%;
      bottom: 0;

      background-color: inherit;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M.748 24a.755.755 0 0 1-.531-.22.754.754 0 0 1-.196-.716l1.77-6.905a.84.84 0 0 1 .045-.121.73.73 0 0 1 .151-.223L16.513 1.289A4.355 4.355 0 0 1 19.611 0c1.178 0 2.277.454 3.106 1.279l.029.029a4.367 4.367 0 0 1 1.251 3.121 4.356 4.356 0 0 1-1.32 3.087L8.183 22.01a.735.735 0 0 1-.231.154.784.784 0 0 1-.111.042L.933 23.978A.773.773 0 0 1 .748 24zm1.041-1.791l4.41-1.131-3.281-3.275-1.129 4.406zm5.868-1.795l13.02-13.02-4.074-4.074L3.58 16.344l4.077 4.07zM21.736 6.332a2.893 2.893 0 0 0-.059-3.972l-.02-.02a2.872 2.872 0 0 0-2.037-.84v-.375l-.001.375a2.873 2.873 0 0 0-1.954.762l4.071 4.07z' fill='%23000000'/%3E%3C/svg%3E");
      background-size: cover;
      box-shadow: inherit;
    }
  }
}

.editable-ghost.editable-open ~ .editable-toggle-button {
  display: none;
}

.editable-min-width-150 {
  min-width: 150px;
}

.editable-min-width-300 {
  min-width: 300px;
}
