/**
 * Objects
 * MASCOT
 */

.mascot {
}

.mascot-sad {
  .mouth {
    transform-origin: 50%;
    transform: rotate(180deg);
    transform-box: fill-box;
  }
}

// Enable animations only for Firefox when it supports `transform-box`
// Otherwise the transform origin is broken and elements are badly positioned
@supports ((transform-box: fill-box) or (-webkit-appearance: none)) {
  .mascot-animated {
    .mascot-head {
      animation: head 5s infinite;
      transform-origin: 50%;
    }

    .mascot-right-ear {
      animation: rightear 3s infinite;
      transform-origin: bottom left;
      transform-box: fill-box;
    }

    .mascot-left-ear {
      animation: leftear 3s infinite;
      transform-origin: bottom right;
      transform-box: fill-box;
    }
  }
}

@keyframes head {
  60% {
    transform: rotate(-2deg) translateY(1%);
  }
}

@keyframes rightear {
  50% {
    transform: rotate(5deg);
  }
}

@keyframes leftear {
  50% {
    transform: rotate(-5deg);
  }
}
