/**
 * Object
 * LOADER
 */

.loader {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto;

  position: relative;

  overflow: visible;

  .first,
  .second {
    transform-origin: 50%;
    transform-box: fill-box;
    fill: currentColor;
  }

  .first {
    animation: loader-reverse 1.7s infinite ease-out;
  }

  .second {
    animation: loader 1.7s infinite ease-out;
  }
}

@keyframes loader {
  50% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(38%);
  }
  80% {
    transform: translateX(38%) rotate(180deg);
  }
  100% {
    transform: translateX(0) rotate(180deg);
  }
}

@keyframes loader-reverse {
  50% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(-38%);
  }
  80% {
    transform: translateX(-38%) rotate(180deg);
  }
  100% {
    transform: translateX(0) rotate(180deg);
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spinner 2s linear infinite;
}

#loader-backdrop {
  position:fixed;
  width:100%;
  height:100%;
  z-index:99999999999999;
  top:0;
  left:0;
  color:white;
  text-align:center;
  padding-top:20%;
  font-size: 1.5rem;
  background-color: rgba(0,0,0,0.7);
}
@keyframes bounce {
  0%   {top: 0%}
  10%  {top: 1%}
  20%  {top: 4%}
  30%  {top: 9%}
  40%  {top: 16%}
  50%  {top: 25%}
  60%  {top: 36%}
  70%  {top: 49%}
  80%  {top: 64%}
  90%  {top: 81%}
  100% {top: 100%}
}

.mascot{
  background-image: url('../images/mascot.svg');
}
.dancing-mascot{
  height:50px;
  width:200px;
  position:relative;
  margin:0 auto 250px auto;
  img {
    width: 200px;
    position:absolute;
    display: block;
    animation: bounce 0.6s linear infinite alternate;
  }

}

