/**
 * Print optimizations
 */

@media print {
  @page {
    size: landscape;
  }

  .site-content,
  .container-fluid {
    padding: 0;
  }
  .table-responsive {
    overflow-x: inherit;
  }
  .nav,
  .btn,
  .btn-group,
  .pager,
  .well,
  .tablesorter-filter-row,
  footer {
    display: none;
  }
  td {
    font-size: 12px;
  }
}
