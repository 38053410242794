/**
 * Page
 * LOGIN
 */

.login-panel {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.container-login {
  min-height: 100vh;
  width: 100%;
  max-width: 800px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: $base-spacing-unit;
  padding-bottom: $base-spacing-unit;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: $screen-sm-min) {
  .login-body {
    padding: $base-spacing-unit;
  }
}

@media (min-width: $screen-md-min) {
  .login-body {
    padding: $double-spacing-unit;
  }
}
