.tabs {
  @extend .list-unstyled;
  margin-bottom: 0;
  overflow-x: auto;

  white-space: nowrap;

  display: flex;
  column-gap: 2px;

  > li > a {
    display: block;
    padding: 10px 12px;

    color: $brand-alt;
    font-weight: bold;
    text-transform: uppercase;

    border: 2px solid transparent;
    border-bottom-color: $warm-grey-200;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &:hover,
    &:focus {
      text-decoration: none;

      background-color: $warm-grey-100;
    }
  }

  > li.active > a {
    border: 2px solid $warm-grey-200;
    border-bottom-color: $warm-grey-50;

    background-image: linear-gradient(to bottom, white 50%, transparent);

    &:hover {
      background-color: transparent;
    }
  }
}
