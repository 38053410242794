/**
 * Page
 * TECHNOLOGY
 */

.technology-categorisation-form {
  display: table;
  table-layout: fixed;
  margin: 10px -10px;
}

.technology-categorisation-form-item {
  display: table-cell;
  padding: 0 10px;
}

.technology-categorisation-form-item .form-group {
  margin-bottom: 0;
}

.valign-bottom {
  vertical-align: bottom;
}

/**
 * Services grid
 */

.services-grid-technology-list {
  padding-left: 20px;
  margin-bottom: 0;
}

.services-grid-title {
  text-transform: uppercase;
  text-align: center;
  border-bottom-width: 1px !important;
}

/**
 * Technologies comparison
 */

.trends-graph {
  height: 280px;
}
