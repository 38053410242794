.table-vertical-top td {
  display: table-cell;
  vertical-align: top !important;
}

/**
 * Sticky headers for table sorter
 * Original documentation, see https://mottie.github.io/tablesorter/docs/example-widget-sticky-header.html
 */
.table-sticky-header {
  background-color: white;
}
.panel .tablesorter-sticky-wrapper {
  margin-left: 5px;
}

/**
 * Used on the salary list .table-diagonal-headers allow to reduce the size of columns by displaying header at 45 degres
 * This is something directly inspired from: https://css-tricks.com/rotated-table-column-headers
 */
.table-diagonal-headers > thead > tr > th {
  height: 150px;
  min-width: 46px;
}
.table-diagonal-headers > thead > tr > th.tablesorter-header {
  background-position: right 95% !important;
}
.table-diagonal-headers > thead > tr > th > div {
  white-space: nowrap;
  transform: translate(25px, 51px) rotate(315deg);
  width: 30px;
  padding-left: 0;
  padding-right: 0;
}
.table-diagonal-headers > thead > tr > th > div > span {
  border-bottom: 1px solid #ccc;
}
.table-diagonal-headers > thead > tr > th > .tablesorter-header-inner {
  margin-left: -24px;
}
