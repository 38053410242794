// Space out elements horizontally until there’s not enough space
.list-hybrid {
  @extend .list-unstyled;
  margin-bottom: 0;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $half-spacing-unit;
}

@media (max-width: $screen-sm-max) {
  // Make the list children grow to both sides of the container
  // This is useful for a list of buttons for example
  .list-hybrid--grow {
    > * {
      flex: 1 1 auto;
      display: flex;

      > * {
        flex-grow: 1;
      }
    }
  }
}
