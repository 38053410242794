/**
 * Object
 * TABLE SORTER
 */

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

.tablesorter {
  .filtered {
    display: none;
  }

  .tablesorter-filter.active {
    background-color: #fffad4;
  }

  .tablesorter-filter.disabled {
    display: none;
  }

  .tablesorter-filter-row td {
    background-color: $warm-grey-50;
    padding: 4px;
  }

  thead .tablesorter-header {
    &:focus {
      outline: none;
    }

    &:not([data-sorter='false']) {
      padding-right: 20px;

      background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
      background-repeat: no-repeat;
      background-position: center right;
      cursor: pointer;

      &:hover {
        background-color: $warm-grey-100;
      }
    }

    &.tablesorter-headerSortUp,
    &.tablesorter-headerAsc {
      background-color: $warm-grey-50;
      background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
    }

    &.tablesorter-headerSortDown,
    &.tablesorter-headerDesc {
      background-color: $warm-grey-50;
      background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
    }
  }
}
