/**
 * Object
 * FAVORITE
 */

.toggle-favorite {
  &:not(.btn) {
    color: darken($gray-lighter, 10%);
    text-decoration: none;

    &:hover {
      text-decoration: none;
      color: $gray-light;
    }
  }

  &.favorited {
    color: $brand-warning;

    &:hover,
    &:focus {
      color: $brand-warning;
    }

    &.btn-default {
      color: white;

      border-color: $brand-warning;
      background-color: $brand-warning;
    }
  }
}
