/**
 * Base
 * BOOTSTRAP EXTENSION & OVERRIDES
 */

/**
 * Buttons
 */

.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  row-gap: 2px;

  > .btn {
    flex-grow: 1;

    + .btn {
      margin-left: -1px;
    }
  }
}

.btn-primary,
.btn-default,
.btn-default.active,
.btn-danger,
.btn-success,
.btn-warning,
.btn-info,
.nav-pills > li > a,
.nav-tabs > li > a,
.pager > li > a,
.dropdown-menu > li > a {
  &:focus,
  &:active:focus {
    outline: none;
  }
}

.btn-primary,
.btn-danger,
.btn-success,
.btn-warning,
.btn-info,
.pager > li > a {
  &:focus,
  &:active:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  }
}

.btn-primary {
  transition: all 0.2s ease;

  &:hover,
  &:focus {
    background-color: lighten($brand-alt, 8%);
  }

  &:active {
    transition: none;
  }
}

.btn-default {
  &:hover,
  &:focus {
    background-color: $warm-grey-50;
    border-color: $warm-grey-300;
  }

  &.active {
    background-color: $warm-grey-100;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.125) inset;
  }
}

.btn-bare {
  padding: 0;
  background: none;
  border: none;
}

.btn-link {
  color: $gray-light;

  &:focus,
  &:hover {
    color: $gray-dark;
    text-decoration: none;
  }
}

.btn-google {
  padding-left: 42px;
  background-image: url('../images/google.svg');
  background-repeat: no-repeat;
  background-position: 12px 50%;
  background-size: 24px;

  &:active {
    background-image: url('../images/google.svg');
  }
}

.btn-keycloak,
.btn-liip-slashes {
  padding-left: 42px;
  background-image: url('../images/liip-slashes.svg');
  background-repeat: no-repeat;
  background-position: 12px 50%;
  background-size: 36px;

  &:active {
    background-image: url('../images/liip-slashes.svg');
  }
}

.btn-badge {
  margin-top: -2px;
  margin-bottom: -2px;

  position: relative;

  > .badge {
    position: absolute;
    top: 35px;
    left: 50%;

    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
}
/**
 * Panel
 */

.panel {
  margin-bottom: 24px;

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);

  > .table,
  > .table-responsive,
  > .panel-collapse > .table-responsive {
    overflow-y: visible;

    > .table {
      margin: 5px;
      width: calc(100% - 10px);

      &.table-bordered > thead {
        > tr:first-child > th {
          border-bottom: 2px solid $table-border-color;
        }
      }

      tbody {
        tr:first-of-type > td,
        tr:first-of-type > th {
          border-top: 0;
        }
      }

      tbody ~ thead {
        tr:first-of-type > td,
        tr:first-of-type > th {
          border-top-width: 1px;
        }
      }
    }
  }

  > .nav-tabs {
    padding-left: 6px;
    padding-right: 6px;

    > li.active a {
      background-color: white;
    }
  }
}

.panel-default > .panel-heading {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}

.panel-title a {
  color: inherit;
}

.panel-body {
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}

.panel-heading-end,
.panel-footer {
  a:not(.btn) {
    color: $warm-grey-700;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $warm-grey-1000;
    }
  }
}

.panel:target .panel-heading {
  background-color: $highlight-color;
  background-color: $highlight-color;
}

.panel-collapse-toggle {
  display: inline-flex;
  cursor: pointer;

  &:active {
    box-shadow: none;
  }

  .icon-chevron-down {
    will-change: transform;
    transition: 150ms transform ease-out;
  }

  &.collapsed .icon-chevron-down {
    transform: rotate(-90deg);
  }
}

/**
 * Tables
 */

.table {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > td,
      > th {
        vertical-align: middle;

        > :last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  > tfoot > tr {
    > th,
    > td {
      border-top-width: 2px;
    }
  }

  > tbody > tr > th:first-child {
    border-right: 1px dotted $table-border-color;
  }

  .table {
    background-color: transparent;
  }

  .row-separator {
    > td,
    > th {
      border-top-width: 2px;
    }
  }

  .col-separator {
    border-left: 1px solid rgba(0, 0, 0, 0.04);
  }
}

.table-top {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > td,
      > th {
        vertical-align: top;
      }
    }
  }
}

@media screen and (min-width: $screen-sm-min) {
  .table-fixed {
    table-layout: fixed;

    thead,
    tbody,
    tfoot {
      td,
      th {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.w-100 {
  width: 100%;
}

/**
 * Definition lists
 */

.dl-horizontal {
  dt {
    width: 100px;
    float: left;
    text-align: left;
  }
  dd {
    margin-left: 120px;
  }
}

/**
 * Navs
 */

.navbar-fixed-top,
.navbar-fixed-bottom {
  .navbar-collapse {
    // 60px = Topbar height
    // 80vh = We want to keep some whitespace a the bottom so it doesn’t get overlapped by the browser UI especially on iOS
    max-height: calc(80vh - 60px);
  }
}

.navbar-inverse {
  background-image: linear-gradient(45deg, $brand-primary, $brand-secondary);
  border-bottom: 0;
}

.navbar-nav {
  > li {
    > a {
      font-weight: bold;

      @media (min-width: $screen-md-min) {
        padding-left: 9px;
        padding-right: 9px;
      }

      @media (min-width: $screen-lg-min) {
        font-size: 15px;
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    .dropdown-menu {
      border-top: 0;
    }
  }
}

.code-example {
  display: grid;
  margin-bottom: $base-spacing-unit;

  @media (min-width: $screen-md-min) {
    grid-template-columns: 1fr 1fr;
  }
}

.code-example__preview {
  padding: $half-spacing-unit;

  border: 1px solid $pre-border-color;

  @media (max-width: $screen-xs-max) {
    border-bottom: 0;
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
  }
  @media (min-width: $screen-md-min) {
    grid-template-columns: 1fr 1fr;

    border-right: 0;
    border-top-left-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
  }
}

.code-example__code {
  margin-bottom: 0;

  background-color: $warm-grey-100;

  @media (max-width: $screen-xs-max) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  @media (min-width: $screen-md-min) {
    grid-template-columns: 1fr 1fr;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.nav-tabs {
  padding-top: $quarter-spacing-unit;
  background-image: linear-gradient(to top, $warm-grey-100, rgba($warm-grey-100, 0));

  > li {
    > a {
      padding-left: 12px;
      padding-right: 12px;

      color: $brand-alt;
      font-weight: bold;
      text-transform: uppercase;

      &:hover,
      &:focus {
        background-color: $warm-grey-200;
      }
    }

    &.active a {
      padding-left: 18px;
      padding-right: 18px;

      border-color: $warm-grey-300;
      border-bottom-color: transparent;
    }
    &.active > a:hover {
      cursor: pointer;
    }
  }
}

.tab-content {
  margin-top: $half-spacing-unit;
}

.nav-pills {
  margin-bottom: $base-spacing-unit;

  > li {
    > a {
      padding: 8px 12px;

      color: $brand-alt;

      &:hover,
      &:focus,
      &:active {
        background-color: lighten($brand-alt, 45%);
      }
    }

    &.active > a {
      background-color: $brand-alt;
      font-weight: bold;
    }
  }
}

.nav-stacked {
  > li.divider {
    padding-top: 8px;
    margin-top: 10px;

    border-top: 1px solid $warm-grey-200;
  }
}

/**
 * Alerts
 */

.alert {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25));

  // Align text with icon
  > .media > .media-body {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  a:not(.btn) {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .btn-xs {
    margin-top: -2px;
  }

  .btn-sm {
    margin-top: -6px;
  }
}

/**
 * Forms
 */

input[type='radio'],
input[type='checkbox'] {
  margin-top: 2px;
}

@media (min-width: $screen-sm-min) {
  .form-inline .form-group + .form-group {
    margin-left: $half-spacing-unit;
  }

  .form-horizontal .control-label {
    text-align: left;
  }
}

.form-inline {
  @media (min-width: $screen-sm-min) {
    display: inline-block;
  }

  .input-group {
    .btn {
      min-height: 34px;
    }
  }
}

.input-group:last-child {
  margin-bottom: 0;
}

.form-control {
  &:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  }
}

.help-block {
  font-size: 12px;
  margin-bottom: 0;
}

.input-group-xs {
  max-width: 120px;
}

.input-success {
  color: darken($brand-success, 10%);
}

.input-danger {
  color: darken($brand-danger, 10%);
}

/**
 * Typography
 */

.text-danger,
.text-warning,
.text-info,
.text-success,
.text-muted {
  a:not(.btn) {
    color: currentColor;
    text-decoration: underline;
  }
}

/**
 * Grids
 */

.row-lg {
  // Creates a wrapper for a series of columns
  @include make-row(24px);

  @media (min-width: $screen-md-min) {
    @include make-row(48px);

    > [class*='col-'] {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

/**
 * Progress bars
 */

.progress {
  min-width: 150px;
  height: 24px;
  display: flex;
  margin-bottom: 8px;
}
.progress-bar {
  max-width: 100%;
  line-height: 24px;
  font-weight: bold;
  flex: 0 1 auto;
}
.progress-bar-ok {
  @include progress-bar-variant($progress-bar-info-bg);
}
.progress-bar-info {
  @include progress-bar-variant(lightgrey);
}

/**
 * Thumbnails
 */

.thumbnail {
  display: inline-block;
  overflow: hidden;
}

a.thumbnail {
  transition: none;

  &:focus,
  &:hover {
    text-decoration: none;

    background-color: #eee;
    border-color: #bbb;
  }
}

/**
 * Pager
 */

.pager > li > a {
  color: $gray;
}

/**
 * Badges
 */

.badge {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.badge-lg {
  padding-left: 8px;
  padding-right: 8px;

  font-size: $font-size-base;
  line-height: 1.2;
}

/**
 * Breadcrumb
 */

.breadcrumb {
  padding-bottom: 6px;

  border-radius: 0;
  border-bottom: 1px solid #eee;
}

.highlight {
  background-color: $highlight-color;
  transition: background-color 0.2s ease-in-out;
}

/**
 * Lists
 */

.list-inline {
  margin-bottom: 0;
}

/**
 * Modal
 */

.modal-open .modal {
  overflow: hidden;
}

.modal-content {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 60px);
}

.modal-body {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.table.vertical-align-top {
  td {
    vertical-align: top;
  }
}

/**
 * Labels
 */

.label-question {
  background-color: $brand-question;
}
