/**
 * Page
 * PROJECT
 */

.project-rate {
  display: inline-block;
  padding: 0.25em 0.6em;
  margin: 0 0.25em;

  font-size: 1.375rem;
  line-height: 1;
  color: white;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);

  border-radius: 50px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

.project-rate--great {
  font-weight: bold;

  background-color: saturate($brand-success, 20%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset, 0 0 10px 1px lighten(saturate($brand-success, 20%), 10%);
}
.project-rate--good {
  background-color: $brand-success;
}
.project-rate--failing {
  font-weight: bold;
  background-color: $brand-warning;
}
.project-rate--holyshit {
  font-weight: bold;

  background-color: saturate($brand-danger, 20%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset, 0 0 10px 1px lighten(saturate($brand-danger, 20%), 10%);
}

.input-forecast {
  @media (min-width: $screen-md-min) {
    width: 120px;
    display: inline-table;
    vertical-align: middle;
  }
}

.story-graph-tooltip {
  td {
    padding: 0 0.5em;
  }

  tr.subtotal td {
    padding-bottom: 1em;
  }

  tr td:last-child {
    text-align: right;
  }

  tr.subtotal,
  tr.total {
    td:first-child {
      text-align: right;
    }
  }

  tr.total {
    font-weight: bold;
  }
}

.activity-line:target {
  background-color: $highlight-color !important;
}
