/**
 * Page
 * Salary Evaluations
 */

.salary-evaluation {
  margin-bottom: 45px;
  input[type='radio'] {
    display: none;
    &:checked + label {
      background-color: #68ac68;
      border-color: inherit;
      box-shadow: none;
      color: white;
      font-weight: bold;
    }
    &:invalid + label {
      border-color: darkred;
    }
  }
  label {
    background-color: white;
    padding: 15px;
    border: 1px solid #68ac68;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    text-align: center;
    &:hover {
      background-color: #68ac68;
      border-color: inherit;
      color: white;
    }
  }
  textarea {
    border-radius: 0;
    padding: 10px;
    &:invalid {
      border-color: darkred;
    }
    &:valid {
      border-color: green;
    }
  }

  h3 {
    font-weight: normal;
    display: inline-block;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    height: 18px;

    color: #888888;
    strong {
      vertical-align: middle;
      font-weight: bolder;
      height: 100%;
      font-size: 1.4em;
      padding-left: 4px;
      color: black;
    }
  }
  h6 {
    color: #bbbbbb;
  }
  .justification {
    margin-top: 20px;
  }
}

div[data-for] {
  transition: opacity 0.3s ease-in-out;
  .person {
    height: 35px;
    text-align: left;
    img {
      width: 30px;
    }
  }
  label.big {
    font-weight: bold;
  }
  &.inactive {
    .panel-body {
      opacity: 0.2;
    }
    cursor: pointer;
  }
  &.less {
    &.inactive {
      &:before {
        content: ' ';
        background: transparent;
        background: linear-gradient(90deg, rgba(247, 247, 245, 1) 0%, rgba(247, 247, 245, 0) 100%);
        z-index: 99;
        position: absolute;
        height: 100%;
        width: 50%;
        left: 0;
      }
    }
  }
  &.more {
    &.inactive {
      &:before {
        content: ' ';
        background: transparent;
        background: linear-gradient(-90deg, rgba(247, 247, 245, 1) 0%, rgba(247, 247, 245, 0) 100%);
        z-index: 99;
        position: absolute;
        height: 100%;
        width: 50%;
        right: 0;
      }
    }
  }
  &.equal {
  }
}
.greyed-out {
  filter: saturate(0);
  background: repeating-linear-gradient(45deg, #eee, #eee 5px, #fff 5px, #fff 10px);
}
