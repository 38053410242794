/**
 * Pages
 * FEATURES
 */

.features {
  img {
    display: block;
    max-width: 80%;
    max-height: 400px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 4px;
    border: 2px solid lightgray;
  }

  .panel-footer {
    text-align: center;
  }
}

.features:target .panel-heading {
  background-color: $highlight-color;
}

.toggle-feature-feedback {
  color: var(--reaction-color);
  font-weight: bold;

  background-color: white;
  border: 2px solid transparent;

  &.no-vote {
    color: $warm-grey-700;
  }

  &:hover,
  &:focus,
  &.active {
    border-radius: 3px;

    color: var(--reaction-color);
    border-color: currentColor;
  }
}
