.markdown {
  padding: $grid-gutter-width/3 $grid-gutter-width;
  h1,
  h2,
  h3 {
    font-size: 1.5em;
    position: relative;
    &:before {
      content: '#';
      color: $brand-secondary;
      opacity: 0.5;
      margin: 0 5px;
    }
  }
  h2 {
    content: '##';
    font-size: 1.25em;
  }
  h3 {
    content: '###';
    font-size: 1.1em;
  }
}
