/**
 * Object
 * USER
 */

.user-thumb {
  display: block;
  overflow: hidden;

  line-height: 0;

  position: relative;
  border-radius: 5px;

  > img {
    display: block;
    height: 100%;

    position: absolute;
    top: 0;
    left: 50%;

    transform: translateX(-50%);
  }

  &::before {
    display: block;
    content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxIDEiPjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9Im5vbmUiLz48L3N2Zz4=);
    width: 100%;
    height: auto;

    position: relative;
    z-index: 1;

    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
    border-radius: inherit;
  }
}

.user-thumb-small {
  width: 40px;
  height: 40px;
  border-radius: 3px;
}

.current-user-thumb {
  float: left;
  width: 34px;
  height: 34px;
  margin: -7px 7px -7px 0;

  vertical-align: middle;
  border-radius: 3px;

  &::before {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25) inset;
  }

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  > img {
    max-height: 100%;
  }
}
