/*----------------------------------------*\
  CARD
\*----------------------------------------*/

.card {
  display: block;
  height: 100%;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  .panel {
    height: 100%;
    margin-bottom: 0;

    .panel-heading {
      position: relative;
      padding: 0;

      .label {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      img {
        width: 100%;
      }
    }

    .panel-body {
      position: relative;

      .layer {
        min-height: 40px;
        color: #fff;
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;

        &.warning {
          background-color: $brand-warning;
        }

        &.info {
          background-color: $brand-info;
        }

        &.success {
          background-color: $brand-success;
        }

        h4 {
          text-transform: uppercase;
        }
      }

      .panel-title {
        max-width: 300px;
        word-break: break-word;
      }

      p {
        word-break: break-word;
        margin-top: 5px;
        color: $warm-grey-1000;
      }
    }
  }
}
