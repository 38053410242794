/**
 * Pages
 * FEATURES
 */

.documentation {
  img {
    max-width: 100%;
  }
}
