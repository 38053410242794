@include custom-navbar('theme-halloween', rgba(138, 53, 0, 0.83));

.theme-halloween > body {
  background-size: cover;
  background-image: url('https://liip.rokka.io/zebra_big/c183399af53be508ec650a06ca4dd894ff363bdd/preview_v166676570.jpg');
  &:before {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
  }
}
.theme-halloween .navbar-inverse {
  background-color: transparent !important;
  background-image: none !important;
}
.theme-halloween .navbar-collapse {
  background-color: transparent;
}
