.theme-starwars > body {
  background-image: url('/static/images/starwars/background.svg');
  .theme-logo {
    width: 120px;
  }
  .navbar-inverse {
    background-image: url('/static/images/starwars/stars.svg');
    .navbar-nav > li > a {
      text-shadow: 1px 1px 2px #000;
    }
  }
}

.theme-starwars.light > body {
  background-image: url('https://liip.rokka.io/dynamic/o-af-1/4621c413299ed121eb7936e21f1977782fa9ab49.gif');
  background-size: auto 120%;
  background-position: center center;
  background-repeat: none;

  .login-panel {
    background-color: rgba(255, 255, 255, 0.7);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    .theme-logo {
      width: 100%;
      margin-bottom: 20px;
    }
    .form-control {
      background-color: transparent;
      color: white;
    }
    .btn {
      background-color: transparent;
      color: white;
      border: 1px solid $gray-light;
    }
    .panel-footer {
      display: none;
    }
  }
}
