.view-more-content {
  overflow: hidden;
  height: 4.6em;

  &.view-more-collapsed {
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -15px;
      right: -15px;
      box-shadow: inset white 0 -15px 10px;
    }
  }

  &.view-more-expanded {
    overflow: visible;
    height: auto;
  }
}
