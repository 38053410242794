/**
 * Page
 * SEARCH
 */

.search-filters {
  @media (min-width: $screen-md-min) {
    display: block;
  }
}

.search-results-list {
}

.search-results-list-item {
  + .search-results-list-item {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid $warm-grey-200;
  }

  @media (min-width: $screen-sm-min) {
    .media-left {
      padding-right: 15px;
    }
  }

  a {
    color: $gray;

    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }
  }
}

.search-result {
}

.search-result--inline {
  max-height: 100px;
}

.search-result-type {
  margin-bottom: 3px;

  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $text-muted;
}

.search-result-meta {
  color: $text-muted;

  a {
    color: inherit;
  }
}

.search-result-icon {
  display: block;
  width: 29px;
  height: 29px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;

  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;

  @media (min-width: $screen-sm-min) {
    width: 58px;
    height: 58px;
  }
}

.search-result-icon--small {
  width: 37px;
  height: 37px;
}

.search-result-icon-user {
  background-color: #c7c7c7;
}
.search-result-icon-bill {
  background-color: #7db8c8;
}
.search-result-icon-timesheet {
  background-color: #9777a8;
}
.search-result-icon-project {
  background-color: #ed7161;
}
.search-result-icon-contact {
  background-color: #47a0db;
}
.search-result-icon-company {
  background-color: #88c057;
}
.search-result-icon-tracking {
  background-color: #eabf6e;
}
.search-result-icon-role {
  background-color: #79c79e;
}
.search-result-icon-circle {
  background-color: #79c79e;
}
.search-result-icon-free-group {
  background-color: #19b3a6;
}
.search-result-icon-product {
  background-color: #dc708b;
}
.search-result-icon-technology {
  background-color: #8372d2;
}
.search-result-icon-activity {
  background-color: #ed5065;
}
.search-result-icon-page {
  background-color: #1212ed;
}
.search-result-icon-training {
  background-color: #adcf17;
}
.search-result-icon-muted {
  background-color: #555;
}

.search-nav {
  position: relative;
  width: 200px;
  @media (max-width: $screen-sm-min) {
    width: 100%;
  }
}
